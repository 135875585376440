.highlight .hll {
  background-color: #ffeb99; /* Light yellow for highlighted line */
}
.highlight .c {
  color: #6d6d6d; /* Dark grey for comments */
  font-style: italic;
}
.highlight .err {
  color: #e60000; /* Bright red for errors */
  background-color: #f9d6d5; /* Light pink background for errors */
}
.highlight .k {
  color: #0050b3; /* Dark blue for keywords */
  font-weight: 700;
}
.highlight .o {
  color: #0050b3; /* Dark blue for operators */
  font-weight: 700;
}
.highlight .cm {
  color: #6d6d6d; /* Dark grey for comments */
  font-style: italic;
}
.highlight .cp {
  color: #6d6d6d; /* Dark grey for preprocessor */
  font-weight: 700;
  font-style: italic;
}
.highlight .c1 {
  color: #6d6d6d; /* Dark grey for multiline comments */
  font-style: italic;
}
.highlight .cs {
  color: #6d6d6d; /* Dark grey for special comments */
  font-weight: 700;
  font-style: italic;
}
.highlight .gd {
  color: #b30000; /* Dark red for deleted lines */
  background-color: #f2d7d5; /* Light pink background for deleted lines */
}
.highlight .ge {
  color: #4d4d4d; /* Dark grey for emphasis */
  font-style: italic;
}
.highlight .gr {
  color: #e60000; /* Bright red for generic errors */
}
.highlight .gh {
  color: #6d6d6d; /* Dark grey for headings */
}
.highlight .gi {
  color: #008000; /* Green for inserted lines */
  background-color: #d5f2d7; /* Light green background for inserted lines */
}
.highlight .go {
  color: #4d4d4d; /* Dark grey for output */
}
.highlight .gp {
  color: #4d4d4d; /* Dark grey for prompts */
}
.highlight .gs {
  font-weight: 700; /* Bold for strong emphasis */
}
.highlight .gu {
  color: #6d6d6d; /* Dark grey for unclassified text */
}
.highlight .gt {
  color: #e60000; /* Bright red for tags */
}
.highlight .kc {
  color: #0050b3; /* Dark blue for keywords (control) */
  font-weight: 700;
}
.highlight .kd {
  color: #0050b3; /* Dark blue for keywords (declaration) */
  font-weight: 700;
}
.highlight .kn {
  color: #0050b3; /* Dark blue for keywords (namespace) */
  font-weight: 700;
}
.highlight .kp {
  color: #0050b3; /* Dark blue for keywords (pseudo) */
  font-weight: 700;
}
.highlight .kr {
  color: #0050b3; /* Dark blue for keywords (reserved) */
  font-weight: 700;
}
.highlight .kt {
  color: #0050b3; /* Dark blue for types */
  font-weight: 700;
}
.highlight .m {
  color: #006600; /* Dark green for numbers */
}
.highlight .s {
  color: #990000; /* Dark red for strings */
}
.highlight .na {
  color: #007777; /* Teal for attribute names */
}
.highlight .nb {
  color: #0050b3; /* Dark blue for built-ins */
}
.highlight .nc {
  color: #0050b3; /* Dark blue for classes */
  font-weight: 700;
}
.highlight .no {
  color: #007777; /* Teal for object names */
}
.highlight .nd {
  color: #4d4d4d; /* Dark grey for decorators */
  font-weight: 700;
}
.highlight .ni {
  color: #800080; /* Dark purple for import statements */
}
.highlight .ne {
  color: #990000; /* Dark red for errors */
  font-weight: 700;
}
.highlight .nf {
  color: #990000; /* Dark red for functions */
  font-weight: 700;
}
.highlight .nl {
  color: #990000; /* Dark red for labels */
  font-weight: 700;
}
.highlight .nn {
  color: #4d4d4d; /* Dark grey for namespaces */
}
.highlight .nt {
  color: #0050b3; /* Dark blue for tags */
}
.highlight .nv {
  color: #007777; /* Teal for variables */
}
.highlight .ow {
  color: #0050b3; /* Dark blue for keywords (operator words) */
  font-weight: 700;
}
.highlight .w {
  color: #4d4d4d; /* Dark grey for whitespace */
}
.highlight .mf {
  color: #006600; /* Dark green for float numbers */
}
.highlight .mh {
  color: #006600; /* Dark green for hexadecimal numbers */
}
.highlight .mi {
  color: #006600; /* Dark green for integers */
}
.highlight .mo {
  color: #006600; /* Dark green for octal numbers */
}
.highlight .sb {
  color: #990000; /* Dark red for strings (single-quoted) */
}
.highlight .sc {
  color: #990000; /* Dark red for strings (char) */
}
.highlight .sd {
  color: #990000; /* Dark red for docstrings */
}
.highlight .s2 {
  color: #990000; /* Dark red for strings (double-quoted) */
}
.highlight .se {
  color: #990000; /* Dark red for escape sequences */
}
.highlight .sh {
  color: #990000; /* Dark red for strings (here-docs) */
}
.highlight .si {
  color: #990000; /* Dark red for interpolated strings */
}
.highlight .sx {
  color: #990000; /* Dark red for strings (other) */
}
.highlight .sr {
  color: #008000; /* Dark green for string regex */
}
.highlight .s1 {
  color: #990000; /* Dark red for strings (other) */
}
.highlight .ss {
  color: #800080; /* Dark purple for symbols */
}
.highlight .bp {
  color: #6d6d6d; /* Dark grey for built-in pseudo */
}
.highlight .vc {
  color: #007777; /* Teal for variables (class) */
}
.highlight .vg {
  color: #007777; /* Teal for variables (global) */
}
.highlight .vi {
  color: #007777; /* Teal for variables (instance) */
}
.highlight .il {
  color: #006600; /* Dark green for integers (long) */
}
