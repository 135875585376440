
/* Form Subscribe */
.sidebar-card, .form-subscribe {
  background: #f4fce3;
  border: 2px #ccc solid;
  border-radius: 7px;
}
.form-subscribe {
  max-width: 600px;
  margin: 0 auto;
}

.sidebar-card {
  padding: 20px;
}
.sidebar-card ul{
  padding-left: 10px;
  padding-top: 20px;
}

.home-subscribe {
    display: flex;
    justify-content: center;
}
.form-subscribe > div {
  padding: 20px;
}

/* Gumroad subscribe form styles */
.form-subscribe form {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  min-width: 0px;
  display: grid;
  grid-auto-flow: column;
  gap: 0.75rem;
  grid-template-columns: 1fr;
  grid-auto-columns: max-content;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 20px;
}
/*.aside .form-subscribe form {
  display: block;
}*/

.home-subscribe form {
  max-width: 700px;
  padding-bottom: 20px;
}

/*.form-subscribe form button, */
.home-subscribe form button {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  min-width: 0px;
  background: transparent;
  font-size: 1rem;
  line-height: 1.5;
  --line-height: 1.5rem;
  padding: 0.75rem 1rem;
  border: 0.0625rem solid #333;
  color: rgb(250, 250, 250);
  border-radius: 0.25rem;
  font-family: "Roboto", sans-serif;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0px, max-content);
  display: inline-grid;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.14s ease-out 0s;
  background-color: #014F12;
}

/*.aside .form-subscribe form button,*/
.home-subscribe form button {
  width: 100%;
}

/*.form-subscribe form button:hover, */
.home-subscribe form button:hover {
  transform: translate(-0.25rem, -0.25rem);
  box-shadow: #014F12 0.25rem 0.25rem;
  background-color: transparent;
  color: rgb(0, 0, 0);
}
/*.form-subscribe form input, */
.home-subscribe form input {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  min-width: 0px;
  max-width: 600px;
  outline: none;
  font-family: "Mabry Pro", sans-serif;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  --line-height: 1.5rem;
  border: 0.0625rem solid rgb(0, 0, 0);
  border-radius: 0.25rem;
  display: block;
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  --active-bg: #f4f4f0;
}
/*.aside .form-subscribe form input,*/
.home-subscribe form input
{
  max-width: 100%;
  width: 100%;
  margin-bottom: 15px;
}

/*.form-subscribe form input::placeholder, */
.home-subscribe form input::placeholder {
  color: rgb(168, 162, 158);
}
/*.form-subscribe form input:focus-within, */
.home-subscribe form input:focus-within {
  outline: rgb(255, 144, 232) solid 0.125rem;
}
.form-subscribe form input:disabled, .home-subscribe form input:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}
/*.form-subscribe form input:read-only, */
.home-subscribe form input:read-only {
  background-color: rgb(244, 244, 240);
  color: rgb(0, 0, 0);
}

.aside .form-subscribe {
  display: none;
}

@media (min-width: 48em) {
  .home-subscribe {
    display: block;
  }
.aside .form-subscribe {
    display: inline-block;
  }
}