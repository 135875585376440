/*Responsive youtube videos*/
.youtube-responsive {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.youtube-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}