.header {
  padding-top: 20px;
  width: 100%;
  margin-bottom: 40px;
}
.header > .container {
  padding: 10px 20px;
}
.site-title {
  border-bottom: none;
}
#logo {
  height: 30px;
  margin-top: 0;
  margin-bottom: 20px;
}
.nav {
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: 10px 10px;
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  overflow-x: auto;
}
.nav-link {
  text-decoration: none;
  display: inline-block;
  padding: 5px;
  color: #212529;
  margin-right: 8px;
  border-radius: 5px;
  font-size: 0.8rem;
  border-bottom: none;
}
.nav-link:hover {
  text-decoration: none;
  background-color: var(--navbar-bg-hover);
  border-bottom-color: #02781c;
  color:#212529;
}

@media (min-width: 768px) {
  .header {
    padding-left: 20px;
    padding-right: 20px;
  }
  .header > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
    border: 2px solid var(--border-color);
    border-radius: 10px;
  }
  #logo {
    height: 40px;
    margin-bottom: 0;
  }
  .nav {
    font-size: 1rem;
    margin-top: 0;
    background-color: var(--body-bg);
    padding: 0;
    border: none;
  }
  .nav-link {
    padding: 10px;
    color: var(--body-color);
  }
  .nav-link:hover {
    background-color: #ccc;
    background-color: var(--navbar-bg-hover);
  }
}


@media (min-width: 992px) {
  .nav-link {
    font-size: 1rem;
  }
}

@media(min-width: 1320px) {
  .header {
    border: none;
  }
  .header > .container {
    border: 2px solid var(--border-color);
    border-radius: 10px;
  }
}
