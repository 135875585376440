/* Helper classes */
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.align-center {
  align-items: center;
}
.text-small {
  font-size: 0.7rem;
}

.text-center {
  text-align: center;
}
.pt-small {
  padding-top: 1rem;
}
.background-color-light {
  background-color: #f5f7fa;
}
.pad-x-20 {
  padding-right: 20px;
  padding-left: 20px;
}
.pad-b-10 {
  padding-bottom: 10px;
}
.pad-b-20 {
  padding-bottom: 20px;
}
.column {
  padding-bottom: 1em;
}
.width-max-350 {
  max-width: 350px;
}
.column img {
  min-height: auto;
}
.column h2 a, .column h3 a {
/*  text-decoration: none;*/
/*  color: #333;*/
}
.column h2 a:hover, .column h3 a:hover {
/*  color: #02781c;*/
/*  text-decoration: underline;  */
}

.columns {
  margin-top: 30px;

}


.columns-2 {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

@media (min-width: 721px) {
  .columns {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }
  .column {
    flex: 1;
  }
  .columns-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  
}

@media (min-width: 992px) {

  .column img {
/*    padding: 1rem 1.6rem;
    max-width: 50%;*/
  }
}

.tags {
  padding: 3px 10px;
  border-radius: 30px;
  color: var(--gray-700);
  text-decoration: none;
  border: solid 1px var(--gray-700);
  text-transform: lowercase;
  font-size: 0.8em;
  transition-duration: 300ms;
}

.tags:hover {
  border-color: var(--link-hover-color);
}

.button {
  text-decoration: none;
  padding: 6px 20px;
  color: #000;
  border: solid 3px #fff;
  display: block;
  text-align: center;
  border-radius: 3px;
  font-size: 0.9em;
}

.button:active {
  transform: translateY(4px);
  transition: all 0.1s;
}


.button-primary {
  background: var(--link-color);
  border: solid 2px var(--link-color);
  color: var(--body-bg);
  transition-duration: 300ms;
}

.button-primary:hover {
  background-color: var(--green-8);
  color: var(--body-bg);
}

.button-secondary {
  background-color: #ebfbee;
  border: solid 2px var(--link-color);
  transition-duration: 300ms;
}

.button-secondary:hover {
  background-color: #fff;
  border: solid 2px var(--link-hover-color);
}

.t-white, .t-white:hover {
  color: var(--body-bg);
}

.b-green {
  background-color: var(--link-color);
  border: solid 2px var(--link-color);
}
.b-green:hover {
  background-color: var(--green-8);
}
.border-green {
  border: solid 2px #495057;
}
.border-green:hover {
  background-color: #ebfbee;
  border: solid 2px #014F12;
}