/* Ad container to mitigate against CLS issue */
.incontent-ad {
  min-height: 100px;
  background: #ffffee;
  margin-top: 20px;
  margin-bottom: 20px;
}

.incontent-ad-responsive {
  min-height: 300px;
  background: #ffffee;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ad-section-2 {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.ad-container-2 {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  min-height: 50px;
  background: #ffffee;
}

.ad-container-3 {
  max-width: 750px;
  min-height: 250px;
  background: #ffffee;
}

.ad-skyscraper {
  display: none;
}

@media (min-width: 48em) {
  .ad-skyscraper {
    display: block;
    height: 620px;
/*    width: 300px;*/
  background: #ffffee;
  }
}

.sidead-container {
  /*display:none;*/
  max-width: 750px;
  min-height: 90px;
  background: #ffffee;
}

@media (min-width: 48em) {
  .incontent-ad {
    min-height: 320px;
    background: #ffffee;
  }

  .incontent-ad-responsive {
    height: 320px;
    background: #ffffee;
  }

  .sidead-container {
    /*display:block;*/
/*    width: 300px;*/
    min-height: 250px;
    background: #ffffee;
  }
  .ad-container-2 {
    min-height: 250px;
    background: #ffffee;
  }
}
