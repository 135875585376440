/* Hero Section */
.hero {
  padding-left: 2rem;
  padding-right: 2rem;
}

.hero-image {
  display: none;
}
.hero-text {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 992px) {
  .hero {
    max-width: 1440px;
    margin: 0 auto;
  }
  .hero-image {
    display: flex;
    align-items: center;
  }
  .hero-text {
    padding-top: 0px;
    padding-bottom: 0px;
    position: relative;
    left: -70px;
  }
}



.t-size-1, .home-h1 {
  font-size: 1.6rem;
}
.home-h2 {
  font-size: 1.7rem;
}
.t-size-2 {
  font-size: 1.4rem;
}

@media (min-width: 48em) {
  .hero-text {
    padding-bottom: 3rem;
  }
  .t-size-1 {
    font-size: 2.2rem;
  }
  .t-size-2 {
    font-size: 1.5rem;
  }
  .home-h1 {
    font-size: 3rem;
  }
  .home-h2 {
    font-size: 2rem;
  }
}

/* End of Hero Section */