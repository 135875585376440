/* Pagination items can be `span`s or `a`s */
.pagination-item {
  /*display: block;*/
  color: #333;
  padding: 6px 20px;
  border: 1px solid #333;
  border-radius: 5px;
  text-decoration: none;
}
/*.pagination-item:first-child {
  margin-bottom: -1px;
}*/

/* Only provide a hover state for linked pagination items */
a.pagination-item:hover {
  color: #02781c;
  border-color: #02781c;
  background-color: #e7f7e6;
}