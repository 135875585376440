/*Social share links*/
.social-share {
  font-size: 0.85rem;
  padding-bottom: 30px;
}
.social-share a {
  padding: 5px 15px;
  margin-right: 10px;
  border-radius: 30px;
  color: #fff;
  text-decoration: none;
}
.twitter-share-button {
  background-color: #4099ff;
}
.fb-share-button {
  background-color: #3b5998;
}
.social-share-kofi {
  background-color: #6a1b9a;
}
/*End of social share links*/