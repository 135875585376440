.sidebar-cont {
  border-bottom: 3px solid var(--border-color);
  padding-bottom: 30px;
  margin-bottom: 30px;
}
/*.form-subscribe, */
.sidebar-card {
  background: #f4fce3;
  border: 2px solid var(--border-color);
  border-radius: 7px;
  padding: 30px;

  .text {
    h4 {
      font-size: 1.5em;
    }
    p {
        font-size: 1.1em;
    }
  }
}

.sidebar-card ul{
  padding-left: 10px;
  padding-top: 20px;
}

@media(min-width: 500px) {
  .sidebar-card.about {
    display: flex;
    gap: 30px;
  }
    .sidebar-card.about .photo {
      flex: 5;
    }
    .sidebar-card.about .text {
      flex: 7;
    }
}
@media(min-width: 1100px) {
  .sidebar-card.about {
    display: block;
  }
}
