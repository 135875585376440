@media (min-width: 48em) {
  .newsletter {
    padding-bottom: 20px;
  }
}

@media (min-width: 58em) {
  aside .newsletter {
    display: inline-block;
  }
}

@media (max-width: 47em) {
  .about {
    padding-top: 15px;
  }
}

.site-title {
  color: #414141;
  font-size: 1.4rem;
}

/*.aside {
  padding: 1rem 2rem 0rem 1rem;
}*/
.aside a {
  text-decoration: none;
}

.main {
  padding: 2rem 1rem 0rem 1rem;
}

@media (min-width: 48em) {
  .main {
    padding: 2rem 2rem 0rem 2rem;
  }
}


.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* CROSSOVER STYLES */

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  text-rendering: optimizeLegibility;
}

.h2-number {
  font-size: 1.5rem;
}

.h3-number {
  font-size: 1.5rem;
}

/* Body text */

strong {
  color: #303030;
}

/* Misc */
hr {
  border-bottom: 1px solid #fff;
}

/* Code */
code,
pre {
  font-family: var(--body-font);
}
code {
  padding: 0.25em 0.5em;
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--code-bg);
  border-radius: 3px;
}
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: 0.9rem;
  line-height: 1.6;
  white-space: pre;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  background-color: var(--code-bg);
}
pre code {
  padding: 0;
  font-size: 1.1rem;
  font-weight: 400;
  color: inherit;
  background-color: transparent;

}

/* Pygments via Jekyll */
.highlight {
  margin-bottom: 1rem;
  border-radius: 4px;
}
.highlight pre {
  margin-bottom: 0;
}

/* Gist via GitHub Pages */
.gist .gist-file {
  font-family: Menlo, Monaco, "Courier New", monospace !important;
}
.gist .markdown-body {
  padding: 15px;
}
.gist pre {
  padding: 0;
  background-color: transparent;
}
.gist .gist-file .gist-data {
  font-size: 0.8rem !important;
  line-height: 1.4;
}
.gist code {
  padding: 0;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
}

/* Quotes */
blockquote {
  background-color: #ebfbee;
}

img {
  margin: 20px auto;
}

/* Tables */
tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: #f9f9f9;
}
/*Own button styling*/
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  background-color: #014F12;
  border: 0;
  color: #fff;
  cursor: pointer;
  padding: 6px 20px;
  border-radius: 5px;
}

button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
  background: #2f9e44;
}

button:active {
  transform: translateY(4px);
  transition: all 0.1s;
}

@media screen and (min-width: 46.25em) {
  button {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
  }
}
/*End of own button styling*/

/*
 * Custom type
 *
 * Extend paragraphs with `.lead` for larger introductory text.
 */

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

/*
 * Messages
 *
 * Show alert messages to users. You may add it to single elements like a `<p>`,
 * or to a parent if there are multiple elements to show.
 */

.message {
  margin-bottom: 1rem;
  padding: 1rem;
  color: #717171;
  background-color: #f9f9f9;
}

/*
 * Container
 *
 * Center the page content.
 */

.container {
  max-width: 38rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}

/*
 * Masthead
 *
 * Super small header above the content for site name and short description.
 */

.masthead {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 3rem;
}
.masthead-title {
  margin-top: 0;
  margin-bottom: 0;
  color: #505050;
}
.masthead-title a {
  color: #505050;
}
.masthead-title small {
  font-size: 75%;
  font-weight: 400;
  color: #c0c0c0;
  letter-spacing: 0;
}

/*
 * Posts and pages
 *
 * Each post is wrapped in `.post` and is used on default and post layouts. Each
 * page is wrapped in `.page` and is only used on the page layout.
 */

.page,
.post {
  margin-bottom: 4em;
}

/* Blog post or page title */
.page-title,
.post-title,
.post-title a {
  color: #303030;
}
.page-title,
.post-title {
  margin-top: 0;
}

/* Meta data line below post title */
.post-date {
  display: block;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  color: #9a9a9a;
}

/* Related posts */
.related {
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: 1px solid #eee;
}
.related-posts {
  padding-left: 0;
  list-style: none;
}
.related-posts h3 {
  margin-top: 0;
}
.related-posts li small {
  font-size: 75%;
  color: #999;
}
.related-posts li a:hover {
  color: #268bd2;
  text-decoration: none;
}
.related-posts li a:hover small {
  color: inherit;
}

/*
 * Pagination
 *
 * Super lightweight (HTML-wise) blog pagination. `span`s are provide for when
 * there are no more previous or next posts to show.
 */

.pagination {
  /*overflow: hidden; /* clearfix */
  /*margin-left: -1rem;*/
  /*margin-right: -1rem;*/
  /*font-family: "PT Sans", Helvetica, Arial, sans-serif;*/
  /*color: #ccc;*/
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding-bottom: 60px;
}



/*User added styles*/
/*Cards arrangements*/
.cards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
@media (min-width: 48em) {
  .cards {
    flex-wrap: nowrap;
  }
}

/*Style for card component*/
.card {
  transition: 0.3s;
  border-radius: 5px;
  padding: 20px;
  width: 90%;
  margin-bottom: 30px;
  margin-right: 15px;
  text-align: center;
}
.card-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 2px 1px;
}

.card-shadow:hover {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px;
}

.card-row p {
  color: #151515;
}
.card button {
  margin-right: 15px;
}
/*Search Page Styles */
input[type="text"] {
  text-align: center;
  width: 100%;
  height: 50px;
  font-size: 1em;
  border: solid 2px #777;
  border-radius: 5px;
}

#myUL li {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 48em) {
  input[type="text"] {
    min-width: 400px;
  }
}
/*End of Search Page Styles */










