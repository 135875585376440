/*Blog page styles*/
.posts > div > a {
  text-decoration: none;
}

.posts > div > a > strong {
  color: #02781c;
}

.posts > div > h2 > a {
  text-decoration: none;
  color: var(--link-color);
}

.posts > div > h2 > a:hover {
  color: var(--link-hover-color);
}

.posts > div > h2 > a:active {
  color: #02781c;
}

.table-of-content {
  display: inline;
}
.table-of-content a {
  padding-right: 10px;
}