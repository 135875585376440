:root {
  --gray-000: #f8f9fa;
  --gray-100: #f1f3f5;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --gray-600: #868e96;
  --gray-700: #495057;
  --gray-800: #343a40;
  --gray-900: #212529;

  --red: #fa5252;
  --pink: #e64980;
  --grape: #be4bdb;
  --purple: #7950f2;
  --indigo: #4c6ef5;
  --blue: #228be6;
  --cyan: #15aabf;
  --teal: #12b886;
  --green: #40c057;
  --yellow: #fab005;
  --orange: #fd7e14;

  --green-0: #ebfbee;
  --green-1: #d3f9d8;
  --green-2: #b2f2bb;
  --green-3: #8ce99a;
  --green-4: #69db7c;
  --green-5: #51cf66;
  --green-6: #40c057;
  --green-7: #37b24d;
  --green-8: #2f9e44;
  --green-9: #2b8a3e;
  --green-10: #147028;
  --green-11: #014F12;
  --green-12: #02781c;

  --blue-500: #339af0;
  --blue-800: #1971c2;

  --body-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --body-font-size: 18px;
  --body-line-height: 1.5;
  --body-color: var(--gray-900);
  --body-bg: #fefdfe;

  --navbar-bg: var(--gray-100);
  --navbar-bg-hover: var(--green-1);

  --link-color: var(--green-12);
  --link-hover-color: var(--blue-800);

  --heading-color: var(--gray-900);

  --border-color: var(--gray-300);
  --border-radius: .25rem;

  --code-font: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --code-color: var(--grape);
  --code-bg: var(--gray-000);

  --spacer: 1rem;
  --spacer-2: calc(var(--spacer) * 1.5);
  --spacer-3: calc(var(--spacer) * 3);
}

@media (prefers-color-scheme: dark) {
  :root {
    --body-color: var(--gray-000);
    --body-bg: var(--gray-900);

    --navbar-bg: var(--gray-300);
    --navbar-bg-hover: var(--gray-000);
    --navbar-color: var(--gray-900);


    --heading-color: #fff;

    --link-color: var(--blue-300);
    --link-hover-color: var(--blue-400);

    --border-color: rgba(255,255,255,.15);

    --code-bg: #f5ffff;
  }
}
