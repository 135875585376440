/*layout content and sidebar*/

.content {
  width: 100%;
}
.main {
  padding: 20px;
  width: 100%;
  max-width: 845px;
  margin: 0 auto;
}
.aside {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  display: none;
}

@media (min-width: 1100px) {
  .content {
    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto 370px;
    grid-column-gap: 35px;
  }
  .main {
    margin-left: 0;
  }
  .aside {
    display: block;
  }
  .content.fullwidth {
    display: block;
    .main {
       max-width: 1280px;
    }
  }
}

@media(min-width: 1320px) {
  .main {
    padding-left: 0;
    padding-right: 0;
  }
  .aside {
    padding-right: 0;
  }
}
