/* Footer Styling */
footer {
  padding: 20px;
  margin-bottom: 60px;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 25px;
  width: 100%;
  border: 2px solid var(--border-color);
  border-radius: 10px;
  padding: 20px 22px;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-column ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.footer-column ul li {
    margin-bottom: 10px;
    margin-left: 0;
}

.footer-link {
  font-size: 1rem;
  text-decoration: none;
  color: var(--body-color);
  font-weight: 400;
  border-bottom: none;  
}

.footer-link:hover {
  color: var(--body-color);
  border-bottom: 1px solid var(--body-color);
}

@media (min-width: 48em) {
  footer {
    padding-bottom: 1rem;
    margin-bottom: 100px;
  }
  footer div a {
    margin-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  footer {
    max-width: 1280px;
    margin: 0 auto;
  }
}

@media (min-width: 1320px) {
  footer {
    padding-left: 0;
    padding-right: 0;
  }
}
