/*Bio section*/
.bio {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  border: 2px #ccc solid;
  background: #f4fce3;
  border-radius: 7px;
}
.bio img {
  max-width: 150px;
  min-width: 150px;
  min-height: 150px;
  max-height: 150px;
  border-radius: 50%;
}
.bio > div {
  padding: 20px;
}
@media (min-width: 48em) {
  .bio {
    display: flex;
    flex-wrap: nowrap;
  }
}
